export const categories = [
  {
    id: 111,
    name: "前端技术",
    createdAt: "2020-02-21 15:19:17",
    updatedAt: "2020-02-21 15:19:17"
  },
  {
    id: 112,
    name: "人生感悟",
    createdAt: "2020-02-20 17:54:19",
    updatedAt: "2020-02-20 17:54:19"
  },
  {
    id: 113,
    name: "后端技术",
    createdAt: "2020-02-20 17:54:14",
    updatedAt: "2020-02-20 17:54:14"
  }
];
