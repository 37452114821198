export const list = [
  {
    id: "new",
    name: "最新的4.7版，新增了41个图标",
    icons: [
      { class: "fa fa-address-book", name: "address-book" },
      { class: "fa fa-address-book-o", name: "address-book-o" },
      { class: "fa fa-address-card", name: "address-card" },
      { class: "fa fa-address-card-o", name: "address-card-o" },
      { class: "fa fa-bandcamp", name: "bandcamp" },
      { class: "fa fa-bath", name: "bath" },
      { class: "fa fa-bathtub", name: "bathtub" },
      { class: "fa fa-drivers-license", name: "drivers-license" },
      { class: "fa fa-drivers-license-o", name: "drivers-license-o" },
      { class: "fa fa-eercast", name: "eercast" },
      { class: "fa fa-envelope-open", name: "envelope-open" },
      { class: "fa fa-envelope-open-o", name: "envelope-open-o" },
      { class: "fa fa-etsy", name: "etsy" },
      { class: "fa fa-free-code-camp", name: "free-code-camp" },
      { class: "fa fa-grav", name: "grav" },
      { class: "fa fa-handshake-o", name: "handshake-o" },
      { class: "fa fa-id-badge", name: "id-badge" },
      { class: "fa fa-id-card", name: "id-card" },
      { class: "fa fa-id-card-o", name: "id-card-o" },
      { class: "fa fa-imdb", name: "imdb" },
      { class: "fa fa-linode", name: "linode" },
      { class: "fa fa-meetup", name: "meetup" },
      { class: "fa fa-microchip", name: "microchip" },
      { class: "fa fa-podcast", name: "podcast" },
      { class: "fa fa-quora", name: "quora" },
      { class: "fa fa-ravelry", name: "ravelry" },
      { class: "fa fa-s15", name: "s15" },
      { class: "fa fa-shower", name: "shower" },
      { class: "fa fa-snowflake-o", name: "snowflake-o" },
      { class: "fa fa-superpowers", name: "superpowers" },
      { class: "fa fa-telegram", name: "telegram" },
      { class: "fa fa-thermometer", name: "thermometer" },
      { class: "fa fa-thermometer-0", name: "thermometer-0" },
      { class: "fa fa-thermometer-1", name: "thermometer-1" },
      { class: "fa fa-thermometer-2", name: "thermometer-2" },
      { class: "fa fa-thermometer-3", name: "thermometer-3" },
      { class: "fa fa-thermometer-4", name: "thermometer-4" },
      { class: "fa fa-thermometer-empty", name: "thermometer-empty" },
      { class: "fa fa-thermometer-full", name: "thermometer-full" },
      { class: "fa fa-thermometer-half", name: "thermometer-half" },
      { class: "fa fa-thermometer-quarter", name: "thermometer-quarter" },
      {
        class: "fa fa-thermometer-three-quarters",
        name: "thermometer-three-quarters"
      },
      { class: "fa fa-times-rectangle", name: "times-rectangle" },
      { class: "fa fa-times-rectangle-o", name: "times-rectangle-o" },
      { class: "fa fa-user-circle", name: "user-circle" },
      { class: "fa fa-user-circle-o", name: "user-circle-o" },
      { class: "fa fa-user-o", name: "user-o" },
      { class: "fa fa-vcard", name: "vcard" },
      { class: "fa fa-vcard-o", name: "vcard-o" },
      { class: "fa fa-window-close", name: "window-close" },
      { class: "fa fa-window-close-o", name: "window-close-o" },
      { class: "fa fa-window-maximize", name: "window-maximize" },
      { class: "fa fa-window-minimize", name: "window-minimize" },
      { class: "fa fa-window-restore", name: "window-restore" },
      { class: "fa fa-wpexplorer", name: "wpexplorer" }
    ]
  },
  {
    id: "web-application",
    name: "Web Application Icons",
    icons: [
      { class: "fa fa-address-book", name: "address-book" },
      { class: "fa fa-address-book-o", name: "address-book-o" },
      { class: "fa fa-address-card", name: "address-card" },
      { class: "fa fa-address-card-o", name: "address-card-o" },
      { class: "fa fa-adjust", name: "adjust" },
      {
        class: "fa fa-american-sign-language-interpreting",
        name: "american-sign-language-interpreting"
      },
      { class: "fa fa-anchor", name: "anchor" },
      { class: "fa fa-archive", name: "archive" },
      { class: "fa fa-area-chart", name: "area-chart" },
      { class: "fa fa-arrows", name: "arrows" },
      { class: "fa fa-arrows-h", name: "arrows-h" },
      { class: "fa fa-arrows-v", name: "arrows-v" },
      { class: "fa fa-asl-interpreting", name: "asl-interpreting" },
      {
        class: "fa fa-assistive-listening-systems",
        name: "assistive-listening-systems"
      },
      { class: "fa fa-asterisk", name: "asterisk" },
      { class: "fa fa-at", name: "at" },
      { class: "fa fa-audio-description", name: "audio-description" },
      { class: "fa fa-automobile", name: "automobile" },
      { class: "fa fa-balance-scale", name: "balance-scale" },
      { class: "fa fa-ban", name: "ban" },
      { class: "fa fa-bank", name: "bank" },
      { class: "fa fa-bar-chart", name: "bar-chart" },
      { class: "fa fa-bar-chart-o", name: "bar-chart-o" },
      { class: "fa fa-barcode", name: "barcode" },
      { class: "fa fa-bars", name: "bars" },
      { class: "fa fa-bath", name: "bath" },
      { class: "fa fa-bathtub", name: "bathtub" },
      { class: "fa fa-battery", name: "battery" },
      { class: "fa fa-battery-0", name: "battery-0" },
      { class: "fa fa-battery-1", name: "battery-1" },
      { class: "fa fa-battery-2", name: "battery-2" },
      { class: "fa fa-battery-3", name: "battery-3" },
      { class: "fa fa-battery-4", name: "battery-4" },
      { class: "fa fa-battery-empty", name: "battery-empty" },
      { class: "fa fa-battery-full", name: "battery-full" },
      { class: "fa fa-battery-half", name: "battery-half" },
      { class: "fa fa-battery-quarter", name: "battery-quarter" },
      { class: "fa fa-battery-three-quarters", name: "battery-three-quarters" },
      { class: "fa fa-bed", name: "bed" },
      { class: "fa fa-beer", name: "beer" },
      { class: "fa fa-bell", name: "bell" },
      { class: "fa fa-bell-o", name: "bell-o" },
      { class: "fa fa-bell-slash", name: "bell-slash" },
      { class: "fa fa-bell-slash-o", name: "bell-slash-o" },
      { class: "fa fa-bicycle", name: "bicycle" },
      { class: "fa fa-binoculars", name: "binoculars" },
      { class: "fa fa-birthday-cake", name: "birthday-cake" },
      { class: "fa fa-blind", name: "blind" },
      { class: "fa fa-bluetooth", name: "bluetooth" },
      { class: "fa fa-bluetooth-b", name: "bluetooth-b" },
      { class: "fa fa-bolt", name: "bolt" },
      { class: "fa fa-bomb", name: "bomb" },
      { class: "fa fa-book", name: "book" },
      { class: "fa fa-bookmark", name: "bookmark" },
      { class: "fa fa-bookmark-o", name: "bookmark-o" },
      { class: "fa fa-braille", name: "braille" },
      { class: "fa fa-briefcase", name: "briefcase" },
      { class: "fa fa-bug", name: "bug" },
      { class: "fa fa-building", name: "building" },
      { class: "fa fa-building-o", name: "building-o" },
      { class: "fa fa-bullhorn", name: "bullhorn" },
      { class: "fa fa-bullseye", name: "bullseye" },
      { class: "fa fa-bus", name: "bus" },
      { class: "fa fa-cab", name: "cab" },
      { class: "fa fa-calculator", name: "calculator" },
      { class: "fa fa-calendar", name: "calendar" },
      { class: "fa fa-calendar-check-o", name: "calendar-check-o" },
      { class: "fa fa-calendar-minus-o", name: "calendar-minus-o" },
      { class: "fa fa-calendar-o", name: "calendar-o" },
      { class: "fa fa-calendar-plus-o", name: "calendar-plus-o" },
      { class: "fa fa-calendar-times-o", name: "calendar-times-o" },
      { class: "fa fa-camera", name: "camera" },
      { class: "fa fa-camera-retro", name: "camera-retro" },
      { class: "fa fa-car", name: "car" },
      { class: "fa fa-caret-square-o-down", name: "caret-square-o-down" },
      { class: "fa fa-caret-square-o-left", name: "caret-square-o-left" },
      { class: "fa fa-caret-square-o-right", name: "caret-square-o-right" },
      { class: "fa fa-caret-square-o-up", name: "caret-square-o-up" },
      { class: "fa fa-cart-arrow-down", name: "cart-arrow-down" },
      { class: "fa fa-cart-plus", name: "cart-plus" },
      { class: "fa fa-cc", name: "cc" },
      { class: "fa fa-certificate", name: "certificate" },
      { class: "fa fa-check", name: "check" },
      { class: "fa fa-check-circle", name: "check-circle" },
      { class: "fa fa-check-circle-o", name: "check-circle-o" },
      { class: "fa fa-check-square", name: "check-square" },
      { class: "fa fa-check-square-o", name: "check-square-o" },
      { class: "fa fa-child", name: "child" },
      { class: "fa fa-circle", name: "circle" },
      { class: "fa fa-circle-o", name: "circle-o" },
      { class: "fa fa-circle-o-notch", name: "circle-o-notch" },
      { class: "fa fa-circle-thin", name: "circle-thin" },
      { class: "fa fa-clock-o", name: "clock-o" },
      { class: "fa fa-clone", name: "clone" },
      { class: "fa fa-close", name: "close" },
      { class: "fa fa-cloud", name: "cloud" },
      { class: "fa fa-cloud-download", name: "cloud-download" },
      { class: "fa fa-cloud-upload", name: "cloud-upload" },
      { class: "fa fa-code", name: "code" },
      { class: "fa fa-code-fork", name: "code-fork" },
      { class: "fa fa-coffee", name: "coffee" },
      { class: "fa fa-cog", name: "cog" },
      { class: "fa fa-cogs", name: "cogs" },
      { class: "fa fa-comment", name: "comment" },
      { class: "fa fa-comment-o", name: "comment-o" },
      { class: "fa fa-commenting", name: "commenting" },
      { class: "fa fa-commenting-o", name: "commenting-o" },
      { class: "fa fa-comments", name: "comments" },
      { class: "fa fa-comments-o", name: "comments-o" },
      { class: "fa fa-compass", name: "compass" },
      { class: "fa fa-copyright", name: "copyright" },
      { class: "fa fa-creative-commons", name: "creative-commons" },
      { class: "fa fa-credit-card", name: "credit-card" },
      { class: "fa fa-credit-card-alt", name: "credit-card-alt" },
      { class: "fa fa-crop", name: "crop" },
      { class: "fa fa-crosshairs", name: "crosshairs" },
      { class: "fa fa-cube", name: "cube" },
      { class: "fa fa-cubes", name: "cubes" },
      { class: "fa fa-cutlery", name: "cutlery" },
      { class: "fa fa-dashboard", name: "dashboard" },
      { class: "fa fa-database", name: "database" },
      { class: "fa fa-deaf", name: "deaf" },
      { class: "fa fa-deafness", name: "deafness" },
      { class: "fa fa-desktop", name: "desktop" },
      { class: "fa fa-diamond", name: "diamond" },
      { class: "fa fa-dot-circle-o", name: "dot-circle-o" },
      { class: "fa fa-download", name: "download" },
      { class: "fa fa-drivers-license", name: "drivers-license" },
      { class: "fa fa-drivers-license-o", name: "drivers-license-o" },
      { class: "fa fa-edit", name: "edit" },
      { class: "fa fa-ellipsis-h", name: "ellipsis-h" },
      { class: "fa fa-ellipsis-v", name: "ellipsis-v" },
      { class: "fa fa-envelope", name: "envelope" },
      { class: "fa fa-envelope-o", name: "envelope-o" },
      { class: "fa fa-envelope-open", name: "envelope-open" },
      { class: "fa fa-envelope-open-o", name: "envelope-open-o" },
      { class: "fa fa-envelope-square", name: "envelope-square" },
      { class: "fa fa-eraser", name: "eraser" },
      { class: "fa fa-exchange", name: "exchange" },
      { class: "fa fa-exclamation", name: "exclamation" },
      { class: "fa fa-exclamation-circle", name: "exclamation-circle" },
      { class: "fa fa-exclamation-triangle", name: "exclamation-triangle" },
      { class: "fa fa-external-link", name: "external-link" },
      { class: "fa fa-external-link-square", name: "external-link-square" },
      { class: "fa fa-eye", name: "eye" },
      { class: "fa fa-eye-slash", name: "eye-slash" },
      { class: "fa fa-eyedropper", name: "eyedropper" },
      { class: "fa fa-fax", name: "fax" },
      { class: "fa fa-feed", name: "feed" },
      { class: "fa fa-female", name: "female" },
      { class: "fa fa-fighter-jet", name: "fighter-jet" },
      { class: "fa fa-file-archive-o", name: "file-archive-o" },
      { class: "fa fa-file-audio-o", name: "file-audio-o" },
      { class: "fa fa-file-code-o", name: "file-code-o" },
      { class: "fa fa-file-excel-o", name: "file-excel-o" },
      { class: "fa fa-file-image-o", name: "file-image-o" },
      { class: "fa fa-file-movie-o", name: "file-movie-o" },
      { class: "fa fa-file-pdf-o", name: "file-pdf-o" },
      { class: "fa fa-file-photo-o", name: "file-photo-o" },
      { class: "fa fa-file-picture-o", name: "file-picture-o" },
      { class: "fa fa-file-powerpoint-o", name: "file-powerpoint-o" },
      { class: "fa fa-file-sound-o", name: "file-sound-o" },
      { class: "fa fa-file-video-o", name: "file-video-o" },
      { class: "fa fa-file-word-o", name: "file-word-o" },
      { class: "fa fa-file-zip-o", name: "file-zip-o" },
      { class: "fa fa-film", name: "film" },
      { class: "fa fa-filter", name: "filter" },
      { class: "fa fa-fire", name: "fire" },
      { class: "fa fa-fire-extinguisher", name: "fire-extinguisher" },
      { class: "fa fa-flag", name: "flag" },
      { class: "fa fa-flag-checkered", name: "flag-checkered" },
      { class: "fa fa-flag-o", name: "flag-o" },
      { class: "fa fa-flash", name: "flash" },
      { class: "fa fa-flask", name: "flask" },
      { class: "fa fa-folder", name: "folder" },
      { class: "fa fa-folder-o", name: "folder-o" },
      { class: "fa fa-folder-open", name: "folder-open" },
      { class: "fa fa-folder-open-o", name: "folder-open-o" },
      { class: "fa fa-frown-o", name: "frown-o" },
      { class: "fa fa-futbol-o", name: "futbol-o" },
      { class: "fa fa-gamepad", name: "gamepad" },
      { class: "fa fa-gavel", name: "gavel" },
      { class: "fa fa-gear", name: "gear" },
      { class: "fa fa-gears", name: "gears" },
      { class: "fa fa-gift", name: "gift" },
      { class: "fa fa-glass", name: "glass" },
      { class: "fa fa-globe", name: "globe" },
      { class: "fa fa-graduation-cap", name: "graduation-cap" },
      { class: "fa fa-group", name: "group" },
      { class: "fa fa-hand-grab-o", name: "hand-grab-o" },
      { class: "fa fa-hand-lizard-o", name: "hand-lizard-o" },
      { class: "fa fa-hand-paper-o", name: "hand-paper-o" },
      { class: "fa fa-hand-peace-o", name: "hand-peace-o" },
      { class: "fa fa-hand-pointer-o", name: "hand-pointer-o" },
      { class: "fa fa-hand-rock-o", name: "hand-rock-o" },
      { class: "fa fa-hand-scissors-o", name: "hand-scissors-o" },
      { class: "fa fa-hand-spock-o", name: "hand-spock-o" },
      { class: "fa fa-hand-stop-o", name: "hand-stop-o" },
      { class: "fa fa-handshake-o", name: "handshake-o" },
      { class: "fa fa-hard-of-hearing", name: "hard-of-hearing" },
      { class: "fa fa-hashtag", name: "hashtag" },
      { class: "fa fa-hdd-o", name: "hdd-o" },
      { class: "fa fa-headphones", name: "headphones" },
      { class: "fa fa-heart", name: "heart" },
      { class: "fa fa-heart-o", name: "heart-o" },
      { class: "fa fa-heartbeat", name: "heartbeat" },
      { class: "fa fa-history", name: "history" },
      { class: "fa fa-home", name: "home" },
      { class: "fa fa-hotel", name: "hotel" },
      { class: "fa fa-hourglass", name: "hourglass" },
      { class: "fa fa-hourglass-1", name: "hourglass-1" },
      { class: "fa fa-hourglass-2", name: "hourglass-2" },
      { class: "fa fa-hourglass-3", name: "hourglass-3" },
      { class: "fa fa-hourglass-end", name: "hourglass-end" },
      { class: "fa fa-hourglass-half", name: "hourglass-half" },
      { class: "fa fa-hourglass-o", name: "hourglass-o" },
      { class: "fa fa-hourglass-start", name: "hourglass-start" },
      { class: "fa fa-i-cursor", name: "i-cursor" },
      { class: "fa fa-id-badge", name: "id-badge" },
      { class: "fa fa-id-card", name: "id-card" },
      { class: "fa fa-id-card-o", name: "id-card-o" },
      { class: "fa fa-image", name: "image" },
      { class: "fa fa-inbox", name: "inbox" },
      { class: "fa fa-industry", name: "industry" },
      { class: "fa fa-info", name: "info" },
      { class: "fa fa-info-circle", name: "info-circle" },
      { class: "fa fa-institution", name: "institution" },
      { class: "fa fa-key", name: "key" },
      { class: "fa fa-keyboard-o", name: "keyboard-o" },
      { class: "fa fa-language", name: "language" },
      { class: "fa fa-laptop", name: "laptop" },
      { class: "fa fa-leaf", name: "leaf" },
      { class: "fa fa-legal", name: "legal" },
      { class: "fa fa-lemon-o", name: "lemon-o" },
      { class: "fa fa-level-down", name: "level-down" },
      { class: "fa fa-level-up", name: "level-up" },
      { class: "fa fa-life-bouy", name: "life-bouy" },
      { class: "fa fa-life-buoy", name: "life-buoy" },
      { class: "fa fa-life-ring", name: "life-ring" },
      { class: "fa fa-life-saver", name: "life-saver" },
      { class: "fa fa-lightbulb-o", name: "lightbulb-o" },
      { class: "fa fa-line-chart", name: "line-chart" },
      { class: "fa fa-location-arrow", name: "location-arrow" },
      { class: "fa fa-lock", name: "lock" },
      { class: "fa fa-low-vision", name: "low-vision" },
      { class: "fa fa-magic", name: "magic" },
      { class: "fa fa-magnet", name: "magnet" },
      { class: "fa fa-mail-forward", name: "mail-forward" },
      { class: "fa fa-mail-reply", name: "mail-reply" },
      { class: "fa fa-mail-reply-all", name: "mail-reply-all" },
      { class: "fa fa-male", name: "male" },
      { class: "fa fa-map", name: "map" },
      { class: "fa fa-map-marker", name: "map-marker" },
      { class: "fa fa-map-o", name: "map-o" },
      { class: "fa fa-map-pin", name: "map-pin" },
      { class: "fa fa-map-signs", name: "map-signs" },
      { class: "fa fa-meh-o", name: "meh-o" },
      { class: "fa fa-microchip", name: "microchip" },
      { class: "fa fa-microphone", name: "microphone" },
      { class: "fa fa-microphone-slash", name: "microphone-slash" },
      { class: "fa fa-minus", name: "minus" },
      { class: "fa fa-minus-circle", name: "minus-circle" },
      { class: "fa fa-minus-square", name: "minus-square" },
      { class: "fa fa-minus-square-o", name: "minus-square-o" },
      { class: "fa fa-mobile", name: "mobile" },
      { class: "fa fa-mobile-phone", name: "mobile-phone" },
      { class: "fa fa-money", name: "money" },
      { class: "fa fa-moon-o", name: "moon-o" },
      { class: "fa fa-mortar-board", name: "mortar-board" },
      { class: "fa fa-motorcycle", name: "motorcycle" },
      { class: "fa fa-mouse-pointer", name: "mouse-pointer" },
      { class: "fa fa-music", name: "music" },
      { class: "fa fa-navicon", name: "navicon" },
      { class: "fa fa-newspaper-o", name: "newspaper-o" },
      { class: "fa fa-object-group", name: "object-group" },
      { class: "fa fa-object-ungroup", name: "object-ungroup" },
      { class: "fa fa-paint-brush", name: "paint-brush" },
      { class: "fa fa-paper-plane", name: "paper-plane" },
      { class: "fa fa-paper-plane-o", name: "paper-plane-o" },
      { class: "fa fa-paw", name: "paw" },
      { class: "fa fa-pencil", name: "pencil" },
      { class: "fa fa-pencil-square", name: "pencil-square" },
      { class: "fa fa-pencil-square-o", name: "pencil-square-o" },
      { class: "fa fa-percent", name: "percent" },
      { class: "fa fa-phone", name: "phone" },
      { class: "fa fa-phone-square", name: "phone-square" },
      { class: "fa fa-photo", name: "photo" },
      { class: "fa fa-picture-o", name: "picture-o" },
      { class: "fa fa-pie-chart", name: "pie-chart" },
      { class: "fa fa-plane", name: "plane" },
      { class: "fa fa-plug", name: "plug" },
      { class: "fa fa-plus", name: "plus" },
      { class: "fa fa-plus-circle", name: "plus-circle" },
      { class: "fa fa-plus-square", name: "plus-square" },
      { class: "fa fa-plus-square-o", name: "plus-square-o" },
      { class: "fa fa-podcast", name: "podcast" },
      { class: "fa fa-power-off", name: "power-off" },
      { class: "fa fa-print", name: "print" },
      { class: "fa fa-puzzle-piece", name: "puzzle-piece" },
      { class: "fa fa-qrcode", name: "qrcode" },
      { class: "fa fa-question", name: "question" },
      { class: "fa fa-question-circle", name: "question-circle" },
      { class: "fa fa-question-circle-o", name: "question-circle-o" },
      { class: "fa fa-quote-left", name: "quote-left" },
      { class: "fa fa-quote-right", name: "quote-right" },
      { class: "fa fa-random", name: "random" },
      { class: "fa fa-recycle", name: "recycle" },
      { class: "fa fa-refresh", name: "refresh" },
      { class: "fa fa-registered", name: "registered" },
      { class: "fa fa-remove", name: "remove" },
      { class: "fa fa-reorder", name: "reorder" },
      { class: "fa fa-reply", name: "reply" },
      { class: "fa fa-reply-all", name: "reply-all" },
      { class: "fa fa-retweet", name: "retweet" },
      { class: "fa fa-road", name: "road" },
      { class: "fa fa-rocket", name: "rocket" },
      { class: "fa fa-rss", name: "rss" },
      { class: "fa fa-rss-square", name: "rss-square" },
      { class: "fa fa-s15", name: "s15" },
      { class: "fa fa-search", name: "search" },
      { class: "fa fa-search-minus", name: "search-minus" },
      { class: "fa fa-search-plus", name: "search-plus" },
      { class: "fa fa-send", name: "send" },
      { class: "fa fa-send-o", name: "send-o" },
      { class: "fa fa-server", name: "server" },
      { class: "fa fa-share", name: "share" },
      { class: "fa fa-share-alt", name: "share-alt" },
      { class: "fa fa-share-alt-square", name: "share-alt-square" },
      { class: "fa fa-share-square", name: "share-square" },
      { class: "fa fa-share-square-o", name: "share-square-o" },
      { class: "fa fa-shield", name: "shield" },
      { class: "fa fa-ship", name: "ship" },
      { class: "fa fa-shopping-bag", name: "shopping-bag" },
      { class: "fa fa-shopping-basket", name: "shopping-basket" },
      { class: "fa fa-shopping-cart", name: "shopping-cart" },
      { class: "fa fa-shower", name: "shower" },
      { class: "fa fa-sign-in", name: "sign-in" },
      { class: "fa fa-sign-language", name: "sign-language" },
      { class: "fa fa-sign-out", name: "sign-out" },
      { class: "fa fa-signal", name: "signal" },
      { class: "fa fa-signing", name: "signing" },
      { class: "fa fa-sitemap", name: "sitemap" },
      { class: "fa fa-sliders", name: "sliders" },
      { class: "fa fa-smile-o", name: "smile-o" },
      { class: "fa fa-snowflake-o", name: "snowflake-o" },
      { class: "fa fa-soccer-ball-o", name: "soccer-ball-o" },
      { class: "fa fa-sort", name: "sort" },
      { class: "fa fa-sort-alpha-asc", name: "sort-alpha-asc" },
      { class: "fa fa-sort-alpha-desc", name: "sort-alpha-desc" },
      { class: "fa fa-sort-amount-asc", name: "sort-amount-asc" },
      { class: "fa fa-sort-amount-desc", name: "sort-amount-desc" },
      { class: "fa fa-sort-asc", name: "sort-asc" },
      { class: "fa fa-sort-desc", name: "sort-desc" },
      { class: "fa fa-sort-down", name: "sort-down" },
      { class: "fa fa-sort-numeric-asc", name: "sort-numeric-asc" },
      { class: "fa fa-sort-numeric-desc", name: "sort-numeric-desc" },
      { class: "fa fa-sort-up", name: "sort-up" },
      { class: "fa fa-space-shuttle", name: "space-shuttle" },
      { class: "fa fa-spinner", name: "spinner" },
      { class: "fa fa-spoon", name: "spoon" },
      { class: "fa fa-square", name: "square" },
      { class: "fa fa-square-o", name: "square-o" },
      { class: "fa fa-star", name: "star" },
      { class: "fa fa-star-half", name: "star-half" },
      { class: "fa fa-star-half-empty", name: "star-half-empty" },
      { class: "fa fa-star-half-full", name: "star-half-full" },
      { class: "fa fa-star-half-o", name: "star-half-o" },
      { class: "fa fa-star-o", name: "star-o" },
      { class: "fa fa-sticky-note", name: "sticky-note" },
      { class: "fa fa-sticky-note-o", name: "sticky-note-o" },
      { class: "fa fa-street-view", name: "street-view" },
      { class: "fa fa-suitcase", name: "suitcase" },
      { class: "fa fa-sun-o", name: "sun-o" },
      { class: "fa fa-support", name: "support" },
      { class: "fa fa-tablet", name: "tablet" },
      { class: "fa fa-tachometer", name: "tachometer" },
      { class: "fa fa-tag", name: "tag" },
      { class: "fa fa-tags", name: "tags" },
      { class: "fa fa-tasks", name: "tasks" },
      { class: "fa fa-taxi", name: "taxi" },
      { class: "fa fa-television", name: "television" },
      { class: "fa fa-terminal", name: "terminal" },
      { class: "fa fa-thermometer", name: "thermometer" },
      { class: "fa fa-thermometer-0", name: "thermometer-0" },
      { class: "fa fa-thermometer-1", name: "thermometer-1" },
      { class: "fa fa-thermometer-2", name: "thermometer-2" },
      { class: "fa fa-thermometer-3", name: "thermometer-3" },
      { class: "fa fa-thermometer-4", name: "thermometer-4" },
      { class: "fa fa-thermometer-empty", name: "thermometer-empty" },
      { class: "fa fa-thermometer-full", name: "thermometer-full" },
      { class: "fa fa-thermometer-half", name: "thermometer-half" },
      { class: "fa fa-thermometer-quarter", name: "thermometer-quarter" },
      {
        class: "fa fa-thermometer-three-quarters",
        name: "thermometer-three-quarters"
      },
      { class: "fa fa-thumb-tack", name: "thumb-tack" },
      { class: "fa fa-thumbs-down", name: "thumbs-down" },
      { class: "fa fa-thumbs-o-down", name: "thumbs-o-down" },
      { class: "fa fa-thumbs-o-up", name: "thumbs-o-up" },
      { class: "fa fa-thumbs-up", name: "thumbs-up" },
      { class: "fa fa-ticket", name: "ticket" },
      { class: "fa fa-times", name: "times" },
      { class: "fa fa-times-circle", name: "times-circle" },
      { class: "fa fa-times-circle-o", name: "times-circle-o" },
      { class: "fa fa-times-rectangle", name: "times-rectangle" },
      { class: "fa fa-times-rectangle-o", name: "times-rectangle-o" },
      { class: "fa fa-tint", name: "tint" },
      { class: "fa fa-toggle-down", name: "toggle-down" },
      { class: "fa fa-toggle-left", name: "toggle-left" },
      { class: "fa fa-toggle-off", name: "toggle-off" },
      { class: "fa fa-toggle-on", name: "toggle-on" },
      { class: "fa fa-toggle-right", name: "toggle-right" },
      { class: "fa fa-toggle-up", name: "toggle-up" },
      { class: "fa fa-trademark", name: "trademark" },
      { class: "fa fa-trash", name: "trash" },
      { class: "fa fa-trash-o", name: "trash-o" },
      { class: "fa fa-tree", name: "tree" },
      { class: "fa fa-trophy", name: "trophy" },
      { class: "fa fa-truck", name: "truck" },
      { class: "fa fa-tty", name: "tty" },
      { class: "fa fa-tv", name: "tv" },
      { class: "fa fa-umbrella", name: "umbrella" },
      { class: "fa fa-universal-access", name: "universal-access" },
      { class: "fa fa-university", name: "university" },
      { class: "fa fa-unlock", name: "unlock" },
      { class: "fa fa-unlock-alt", name: "unlock-alt" },
      { class: "fa fa-unsorted", name: "unsorted" },
      { class: "fa fa-upload", name: "upload" },
      { class: "fa fa-user", name: "user" },
      { class: "fa fa-user-circle", name: "user-circle" },
      { class: "fa fa-user-circle-o", name: "user-circle-o" },
      { class: "fa fa-user-o", name: "user-o" },
      { class: "fa fa-user-plus", name: "user-plus" },
      { class: "fa fa-user-secret", name: "user-secret" },
      { class: "fa fa-user-times", name: "user-times" },
      { class: "fa fa-users", name: "users" },
      { class: "fa fa-vcard", name: "vcard" },
      { class: "fa fa-vcard-o", name: "vcard-o" },
      { class: "fa fa-video-camera", name: "video-camera" },
      { class: "fa fa-volume-control-phone", name: "volume-control-phone" },
      { class: "fa fa-volume-down", name: "volume-down" },
      { class: "fa fa-volume-off", name: "volume-off" },
      { class: "fa fa-volume-up", name: "volume-up" },
      { class: "fa fa-warning", name: "warning" },
      { class: "fa fa-wheelchair", name: "wheelchair" },
      { class: "fa fa-wheelchair-alt", name: "wheelchair-alt" },
      { class: "fa fa-wifi", name: "wifi" },
      { class: "fa fa-window-close", name: "window-close" },
      { class: "fa fa-window-close-o", name: "window-close-o" },
      { class: "fa fa-window-maximize", name: "window-maximize" },
      { class: "fa fa-window-minimize", name: "window-minimize" },
      { class: "fa fa-window-restore", name: "window-restore" },
      { class: "fa fa-wrench", name: "wrench" }
    ]
  },
  {
    id: "accessibility",
    name: "Accessibility Icons",
    icons: [
      {
        class: "fa fa-american-sign-language-interpreting",
        name: "american-sign-language-interpreting"
      },
      { class: "fa fa-asl-interpreting", name: "asl-interpreting" },
      {
        class: "fa fa-assistive-listening-systems",
        name: "assistive-listening-systems"
      },
      { class: "fa fa-audio-description", name: "audio-description" },
      { class: "fa fa-blind", name: "blind" },
      { class: "fa fa-braille", name: "braille" },
      { class: "fa fa-cc", name: "cc" },
      { class: "fa fa-deaf", name: "deaf" },
      { class: "fa fa-deafness", name: "deafness" },
      { class: "fa fa-hard-of-hearing", name: "hard-of-hearing" },
      { class: "fa fa-low-vision", name: "low-vision" },
      { class: "fa fa-question-circle-o", name: "question-circle-o" },
      { class: "fa fa-sign-language", name: "sign-language" },
      { class: "fa fa-signing", name: "signing" },
      { class: "fa fa-tty", name: "tty" },
      { class: "fa fa-universal-access", name: "universal-access" },
      { class: "fa fa-volume-control-phone", name: "volume-control-phone" },
      { class: "fa fa-wheelchair", name: "wheelchair" },
      { class: "fa fa-wheelchair-alt", name: "wheelchair-alt" }
    ]
  },
  {
    id: "hand",
    name: "Hand Icons",
    icons: [
      { class: "fa fa-hand-grab-o", name: "hand-grab-o" },
      { class: "fa fa-hand-lizard-o", name: "hand-lizard-o" },
      { class: "fa fa-hand-o-down", name: "hand-o-down" },
      { class: "fa fa-hand-o-left", name: "hand-o-left" },
      { class: "fa fa-hand-o-right", name: "hand-o-right" },
      { class: "fa fa-hand-o-up", name: "hand-o-up" },
      { class: "fa fa-hand-paper-o", name: "hand-paper-o" },
      { class: "fa fa-hand-peace-o", name: "hand-peace-o" },
      { class: "fa fa-hand-pointer-o", name: "hand-pointer-o" },
      { class: "fa fa-hand-rock-o", name: "hand-rock-o" },
      { class: "fa fa-hand-scissors-o", name: "hand-scissors-o" },
      { class: "fa fa-hand-spock-o", name: "hand-spock-o" },
      { class: "fa fa-hand-stop-o", name: "hand-stop-o" },
      { class: "fa fa-thumbs-down", name: "thumbs-down" },
      { class: "fa fa-thumbs-o-down", name: "thumbs-o-down" },
      { class: "fa fa-thumbs-o-up", name: "thumbs-o-up" },
      { class: "fa fa-thumbs-up", name: "thumbs-up" }
    ]
  },
  {
    id: "transportation",
    name: "Transportation Icons",
    icons: [
      { class: "fa fa-ambulance", name: "ambulance" },
      { class: "fa fa-automobile", name: "automobile" },
      { class: "fa fa-bicycle", name: "bicycle" },
      { class: "fa fa-bus", name: "bus" },
      { class: "fa fa-cab", name: "cab" },
      { class: "fa fa-car", name: "car" },
      { class: "fa fa-fighter-jet", name: "fighter-jet" },
      { class: "fa fa-motorcycle", name: "motorcycle" },
      { class: "fa fa-plane", name: "plane" },
      { class: "fa fa-rocket", name: "rocket" },
      { class: "fa fa-ship", name: "ship" },
      { class: "fa fa-space-shuttle", name: "space-shuttle" },
      { class: "fa fa-subway", name: "subway" },
      { class: "fa fa-taxi", name: "taxi" },
      { class: "fa fa-train", name: "train" },
      { class: "fa fa-truck", name: "truck" },
      { class: "fa fa-wheelchair", name: "wheelchair" },
      { class: "fa fa-wheelchair-alt", name: "wheelchair-alt" }
    ]
  },
  {
    id: "gender",
    name: "Gender Icons",
    icons: [
      { class: "fa fa-genderless", name: "genderless" },
      { class: "fa fa-intersex", name: "intersex" },
      { class: "fa fa-mars", name: "mars" },
      { class: "fa fa-mars-double", name: "mars-double" },
      { class: "fa fa-mars-stroke", name: "mars-stroke" },
      { class: "fa fa-mars-stroke-h", name: "mars-stroke-h" },
      { class: "fa fa-mars-stroke-v", name: "mars-stroke-v" },
      { class: "fa fa-mercury", name: "mercury" },
      { class: "fa fa-neuter", name: "neuter" },
      { class: "fa fa-transgender", name: "transgender" },
      { class: "fa fa-transgender-alt", name: "transgender-alt" },
      { class: "fa fa-venus", name: "venus" },
      { class: "fa fa-venus-double", name: "venus-double" },
      { class: "fa fa-venus-mars", name: "venus-mars" }
    ]
  },
  {
    id: "file-type",
    name: "File Type Icons",
    icons: [
      { class: "fa fa-file", name: "file" },
      { class: "fa fa-file-archive-o", name: "file-archive-o" },
      { class: "fa fa-file-audio-o", name: "file-audio-o" },
      { class: "fa fa-file-code-o", name: "file-code-o" },
      { class: "fa fa-file-excel-o", name: "file-excel-o" },
      { class: "fa fa-file-image-o", name: "file-image-o" },
      { class: "fa fa-file-movie-o", name: "file-movie-o" },
      { class: "fa fa-file-o", name: "file-o" },
      { class: "fa fa-file-pdf-o", name: "file-pdf-o" },
      { class: "fa fa-file-photo-o", name: "file-photo-o" },
      { class: "fa fa-file-picture-o", name: "file-picture-o" },
      { class: "fa fa-file-powerpoint-o", name: "file-powerpoint-o" },
      { class: "fa fa-file-sound-o", name: "file-sound-o" },
      { class: "fa fa-file-text", name: "file-text" },
      { class: "fa fa-file-text-o", name: "file-text-o" },
      { class: "fa fa-file-video-o", name: "file-video-o" },
      { class: "fa fa-file-word-o", name: "file-word-o" },
      { class: "fa fa-file-zip-o", name: "file-zip-o" }
    ]
  },
  {
    id: "spinner",
    name: "Spinner Icons",
    icons: [
      {
        class: "fa fa-info-circle fa-lg fa-li",
        name: "info-circle fa-lg fa-li"
      },
      { class: "fa fa-circle-o-notch", name: "circle-o-notch" },
      { class: "fa fa-cog", name: "cog" },
      { class: "fa fa-gear", name: "gear" },
      { class: "fa fa-refresh", name: "refresh" },
      { class: "fa fa-spinner", name: "spinner" }
    ]
  },
  {
    id: "form-control",
    name: "Form Control Icons",
    icons: [
      { class: "fa fa-check-square", name: "check-square" },
      { class: "fa fa-check-square-o", name: "check-square-o" },
      { class: "fa fa-circle", name: "circle" },
      { class: "fa fa-circle-o", name: "circle-o" },
      { class: "fa fa-dot-circle-o", name: "dot-circle-o" },
      { class: "fa fa-minus-square", name: "minus-square" },
      { class: "fa fa-minus-square-o", name: "minus-square-o" },
      { class: "fa fa-plus-square", name: "plus-square" },
      { class: "fa fa-plus-square-o", name: "plus-square-o" },
      { class: "fa fa-square", name: "square" },
      { class: "fa fa-square-o", name: "square-o" }
    ]
  },
  {
    id: "payment",
    name: "Payment Icons",
    icons: [
      { class: "fa fa-cc-amex", name: "cc-amex" },
      { class: "fa fa-cc-diners-club", name: "cc-diners-club" },
      { class: "fa fa-cc-discover", name: "cc-discover" },
      { class: "fa fa-cc-jcb", name: "cc-jcb" },
      { class: "fa fa-cc-mastercard", name: "cc-mastercard" },
      { class: "fa fa-cc-paypal", name: "cc-paypal" },
      { class: "fa fa-cc-stripe", name: "cc-stripe" },
      { class: "fa fa-cc-visa", name: "cc-visa" },
      { class: "fa fa-credit-card", name: "credit-card" },
      { class: "fa fa-credit-card-alt", name: "credit-card-alt" },
      { class: "fa fa-google-wallet", name: "google-wallet" },
      { class: "fa fa-paypal", name: "paypal" }
    ]
  },
  {
    id: "chart",
    name: "Chart Icons",
    icons: [
      { class: "fa fa-area-chart", name: "area-chart" },
      { class: "fa fa-bar-chart", name: "bar-chart" },
      { class: "fa fa-bar-chart-o", name: "bar-chart-o" },
      { class: "fa fa-line-chart", name: "line-chart" },
      { class: "fa fa-pie-chart", name: "pie-chart" }
    ]
  },
  {
    id: "currency",
    name: "Currency Icons",
    icons: [
      { class: "fa fa-bitcoin", name: "bitcoin" },
      { class: "fa fa-btc", name: "btc" },
      { class: "fa fa-cny", name: "cny" },
      { class: "fa fa-dollar", name: "dollar" },
      { class: "fa fa-eur", name: "eur" },
      { class: "fa fa-euro", name: "euro" },
      { class: "fa fa-gbp", name: "gbp" },
      { class: "fa fa-gg", name: "gg" },
      { class: "fa fa-gg-circle", name: "gg-circle" },
      { class: "fa fa-ils", name: "ils" },
      { class: "fa fa-inr", name: "inr" },
      { class: "fa fa-jpy", name: "jpy" },
      { class: "fa fa-krw", name: "krw" },
      { class: "fa fa-money", name: "money" },
      { class: "fa fa-rmb", name: "rmb" },
      { class: "fa fa-rouble", name: "rouble" },
      { class: "fa fa-rub", name: "rub" },
      { class: "fa fa-ruble", name: "ruble" },
      { class: "fa fa-rupee", name: "rupee" },
      { class: "fa fa-shekel", name: "shekel" },
      { class: "fa fa-sheqel", name: "sheqel" },
      { class: "fa fa-try", name: "try" },
      { class: "fa fa-turkish-lira", name: "turkish-lira" },
      { class: "fa fa-usd", name: "usd" },
      { class: "fa fa-won", name: "won" },
      { class: "fa fa-yen", name: "yen" }
    ]
  },
  {
    id: "text-editor",
    name: "Text Editor Icons",
    icons: [
      { class: "fa fa-align-center", name: "align-center" },
      { class: "fa fa-align-justify", name: "align-justify" },
      { class: "fa fa-align-left", name: "align-left" },
      { class: "fa fa-align-right", name: "align-right" },
      { class: "fa fa-bold", name: "bold" },
      { class: "fa fa-chain", name: "chain" },
      { class: "fa fa-chain-broken", name: "chain-broken" },
      { class: "fa fa-clipboard", name: "clipboard" },
      { class: "fa fa-columns", name: "columns" },
      { class: "fa fa-copy", name: "copy" },
      { class: "fa fa-cut", name: "cut" },
      { class: "fa fa-dedent", name: "dedent" },
      { class: "fa fa-eraser", name: "eraser" },
      { class: "fa fa-file", name: "file" },
      { class: "fa fa-file-o", name: "file-o" },
      { class: "fa fa-file-text", name: "file-text" },
      { class: "fa fa-file-text-o", name: "file-text-o" },
      { class: "fa fa-files-o", name: "files-o" },
      { class: "fa fa-floppy-o", name: "floppy-o" },
      { class: "fa fa-font", name: "font" },
      { class: "fa fa-header", name: "header" },
      { class: "fa fa-indent", name: "indent" },
      { class: "fa fa-italic", name: "italic" },
      { class: "fa fa-link", name: "link" },
      { class: "fa fa-list", name: "list" },
      { class: "fa fa-list-alt", name: "list-alt" },
      { class: "fa fa-list-ol", name: "list-ol" },
      { class: "fa fa-list-ul", name: "list-ul" },
      { class: "fa fa-outdent", name: "outdent" },
      { class: "fa fa-paperclip", name: "paperclip" },
      { class: "fa fa-paragraph", name: "paragraph" },
      { class: "fa fa-paste", name: "paste" },
      { class: "fa fa-repeat", name: "repeat" },
      { class: "fa fa-rotate-left", name: "rotate-left" },
      { class: "fa fa-rotate-right", name: "rotate-right" },
      { class: "fa fa-save", name: "save" },
      { class: "fa fa-scissors", name: "scissors" },
      { class: "fa fa-strikethrough", name: "strikethrough" },
      { class: "fa fa-subscript", name: "subscript" },
      { class: "fa fa-superscript", name: "superscript" },
      { class: "fa fa-table", name: "table" },
      { class: "fa fa-text-height", name: "text-height" },
      { class: "fa fa-text-width", name: "text-width" },
      { class: "fa fa-th", name: "th" },
      { class: "fa fa-th-large", name: "th-large" },
      { class: "fa fa-th-list", name: "th-list" },
      { class: "fa fa-underline", name: "underline" },
      { class: "fa fa-undo", name: "undo" },
      { class: "fa fa-unlink", name: "unlink" }
    ]
  },
  {
    id: "directional",
    name: "Directional Icons",
    icons: [
      { class: "fa fa-angle-double-down", name: "angle-double-down" },
      { class: "fa fa-angle-double-left", name: "angle-double-left" },
      { class: "fa fa-angle-double-right", name: "angle-double-right" },
      { class: "fa fa-angle-double-up", name: "angle-double-up" },
      { class: "fa fa-angle-down", name: "angle-down" },
      { class: "fa fa-angle-left", name: "angle-left" },
      { class: "fa fa-angle-right", name: "angle-right" },
      { class: "fa fa-angle-up", name: "angle-up" },
      { class: "fa fa-arrow-circle-down", name: "arrow-circle-down" },
      { class: "fa fa-arrow-circle-left", name: "arrow-circle-left" },
      { class: "fa fa-arrow-circle-o-down", name: "arrow-circle-o-down" },
      { class: "fa fa-arrow-circle-o-left", name: "arrow-circle-o-left" },
      { class: "fa fa-arrow-circle-o-right", name: "arrow-circle-o-right" },
      { class: "fa fa-arrow-circle-o-up", name: "arrow-circle-o-up" },
      { class: "fa fa-arrow-circle-right", name: "arrow-circle-right" },
      { class: "fa fa-arrow-circle-up", name: "arrow-circle-up" },
      { class: "fa fa-arrow-down", name: "arrow-down" },
      { class: "fa fa-arrow-left", name: "arrow-left" },
      { class: "fa fa-arrow-right", name: "arrow-right" },
      { class: "fa fa-arrow-up", name: "arrow-up" },
      { class: "fa fa-arrows", name: "arrows" },
      { class: "fa fa-arrows-alt", name: "arrows-alt" },
      { class: "fa fa-arrows-h", name: "arrows-h" },
      { class: "fa fa-arrows-v", name: "arrows-v" },
      { class: "fa fa-caret-down", name: "caret-down" },
      { class: "fa fa-caret-left", name: "caret-left" },
      { class: "fa fa-caret-right", name: "caret-right" },
      { class: "fa fa-caret-square-o-down", name: "caret-square-o-down" },
      { class: "fa fa-caret-square-o-left", name: "caret-square-o-left" },
      { class: "fa fa-caret-square-o-right", name: "caret-square-o-right" },
      { class: "fa fa-caret-square-o-up", name: "caret-square-o-up" },
      { class: "fa fa-caret-up", name: "caret-up" },
      { class: "fa fa-chevron-circle-down", name: "chevron-circle-down" },
      { class: "fa fa-chevron-circle-left", name: "chevron-circle-left" },
      { class: "fa fa-chevron-circle-right", name: "chevron-circle-right" },
      { class: "fa fa-chevron-circle-up", name: "chevron-circle-up" },
      { class: "fa fa-chevron-down", name: "chevron-down" },
      { class: "fa fa-chevron-left", name: "chevron-left" },
      { class: "fa fa-chevron-right", name: "chevron-right" },
      { class: "fa fa-chevron-up", name: "chevron-up" },
      { class: "fa fa-exchange", name: "exchange" },
      { class: "fa fa-hand-o-down", name: "hand-o-down" },
      { class: "fa fa-hand-o-left", name: "hand-o-left" },
      { class: "fa fa-hand-o-right", name: "hand-o-right" },
      { class: "fa fa-hand-o-up", name: "hand-o-up" },
      { class: "fa fa-long-arrow-down", name: "long-arrow-down" },
      { class: "fa fa-long-arrow-left", name: "long-arrow-left" },
      { class: "fa fa-long-arrow-right", name: "long-arrow-right" },
      { class: "fa fa-long-arrow-up", name: "long-arrow-up" },
      { class: "fa fa-toggle-down", name: "toggle-down" },
      { class: "fa fa-toggle-left", name: "toggle-left" },
      { class: "fa fa-toggle-right", name: "toggle-right" },
      { class: "fa fa-toggle-up", name: "toggle-up" }
    ]
  },
  {
    id: "video-player",
    name: "Video Player Icons",
    icons: [
      { class: "fa fa-arrows-alt", name: "arrows-alt" },
      { class: "fa fa-backward", name: "backward" },
      { class: "fa fa-compress", name: "compress" },
      { class: "fa fa-eject", name: "eject" },
      { class: "fa fa-expand", name: "expand" },
      { class: "fa fa-fast-backward", name: "fast-backward" },
      { class: "fa fa-fast-forward", name: "fast-forward" },
      { class: "fa fa-forward", name: "forward" },
      { class: "fa fa-pause", name: "pause" },
      { class: "fa fa-pause-circle", name: "pause-circle" },
      { class: "fa fa-pause-circle-o", name: "pause-circle-o" },
      { class: "fa fa-play", name: "play" },
      { class: "fa fa-play-circle", name: "play-circle" },
      { class: "fa fa-play-circle-o", name: "play-circle-o" },
      { class: "fa fa-random", name: "random" },
      { class: "fa fa-step-backward", name: "step-backward" },
      { class: "fa fa-step-forward", name: "step-forward" },
      { class: "fa fa-stop", name: "stop" },
      { class: "fa fa-stop-circle", name: "stop-circle" },
      { class: "fa fa-stop-circle-o", name: "stop-circle-o" },
      { class: "fa fa-youtube-play", name: "youtube-play" }
    ]
  },
  {
    id: "brand",
    name: "Brand Icons",
    icons: [
      { class: "fa fa-500px", name: "500px" },
      { class: "fa fa-adn", name: "adn" },
      { class: "fa fa-amazon", name: "amazon" },
      { class: "fa fa-android", name: "android" },
      { class: "fa fa-angellist", name: "angellist" },
      { class: "fa fa-apple", name: "apple" },
      { class: "fa fa-bandcamp", name: "bandcamp" },
      { class: "fa fa-behance", name: "behance" },
      { class: "fa fa-behance-square", name: "behance-square" },
      { class: "fa fa-bitbucket", name: "bitbucket" },
      { class: "fa fa-bitbucket-square", name: "bitbucket-square" },
      { class: "fa fa-bitcoin", name: "bitcoin" },
      { class: "fa fa-black-tie", name: "black-tie" },
      { class: "fa fa-bluetooth", name: "bluetooth" },
      { class: "fa fa-bluetooth-b", name: "bluetooth-b" },
      { class: "fa fa-btc", name: "btc" },
      { class: "fa fa-buysellads", name: "buysellads" },
      { class: "fa fa-cc-amex", name: "cc-amex" },
      { class: "fa fa-cc-diners-club", name: "cc-diners-club" },
      { class: "fa fa-cc-discover", name: "cc-discover" },
      { class: "fa fa-cc-jcb", name: "cc-jcb" },
      { class: "fa fa-cc-mastercard", name: "cc-mastercard" },
      { class: "fa fa-cc-paypal", name: "cc-paypal" },
      { class: "fa fa-cc-stripe", name: "cc-stripe" },
      { class: "fa fa-cc-visa", name: "cc-visa" },
      { class: "fa fa-chrome", name: "chrome" },
      { class: "fa fa-codepen", name: "codepen" },
      { class: "fa fa-codiepie", name: "codiepie" },
      { class: "fa fa-connectdevelop", name: "connectdevelop" },
      { class: "fa fa-contao", name: "contao" },
      { class: "fa fa-css3", name: "css3" },
      { class: "fa fa-dashcube", name: "dashcube" },
      { class: "fa fa-delicious", name: "delicious" },
      { class: "fa fa-deviantart", name: "deviantart" },
      { class: "fa fa-digg", name: "digg" },
      { class: "fa fa-dribbble", name: "dribbble" },
      { class: "fa fa-dropbox", name: "dropbox" },
      { class: "fa fa-drupal", name: "drupal" },
      { class: "fa fa-edge", name: "edge" },
      { class: "fa fa-eercast", name: "eercast" },
      { class: "fa fa-empire", name: "empire" },
      { class: "fa fa-envira", name: "envira" },
      { class: "fa fa-etsy", name: "etsy" },
      { class: "fa fa-expeditedssl", name: "expeditedssl" },
      { class: "fa fa-fa", name: "fa" },
      { class: "fa fa-facebook", name: "facebook" },
      { class: "fa fa-facebook-f", name: "facebook-f" },
      { class: "fa fa-facebook-official", name: "facebook-official" },
      { class: "fa fa-facebook-square", name: "facebook-square" },
      { class: "fa fa-firefox", name: "firefox" },
      { class: "fa fa-first-order", name: "first-order" },
      { class: "fa fa-flickr", name: "flickr" },
      { class: "fa fa-font-awesome", name: "font-awesome" },
      { class: "fa fa-fonticons", name: "fonticons" },
      { class: "fa fa-fort-awesome", name: "fort-awesome" },
      { class: "fa fa-forumbee", name: "forumbee" },
      { class: "fa fa-foursquare", name: "foursquare" },
      { class: "fa fa-free-code-camp", name: "free-code-camp" },
      { class: "fa fa-ge", name: "ge" },
      { class: "fa fa-get-pocket", name: "get-pocket" },
      { class: "fa fa-gg", name: "gg" },
      { class: "fa fa-gg-circle", name: "gg-circle" },
      { class: "fa fa-git", name: "git" },
      { class: "fa fa-git-square", name: "git-square" },
      { class: "fa fa-github", name: "github" },
      { class: "fa fa-github-alt", name: "github-alt" },
      { class: "fa fa-github-square", name: "github-square" },
      { class: "fa fa-gitlab", name: "gitlab" },
      { class: "fa fa-gittip", name: "gittip" },
      { class: "fa fa-glide", name: "glide" },
      { class: "fa fa-glide-g", name: "glide-g" },
      { class: "fa fa-google", name: "google" },
      { class: "fa fa-google-plus", name: "google-plus" },
      { class: "fa fa-google-plus-circle", name: "google-plus-circle" },
      { class: "fa fa-google-plus-official", name: "google-plus-official" },
      { class: "fa fa-google-plus-square", name: "google-plus-square" },
      { class: "fa fa-google-wallet", name: "google-wallet" },
      { class: "fa fa-gratipay", name: "gratipay" },
      { class: "fa fa-grav", name: "grav" },
      { class: "fa fa-hacker-news", name: "hacker-news" },
      { class: "fa fa-houzz", name: "houzz" },
      { class: "fa fa-html5", name: "html5" },
      { class: "fa fa-imdb", name: "imdb" },
      { class: "fa fa-instagram", name: "instagram" },
      { class: "fa fa-internet-explorer", name: "internet-explorer" },
      { class: "fa fa-ioxhost", name: "ioxhost" },
      { class: "fa fa-joomla", name: "joomla" },
      { class: "fa fa-jsfiddle", name: "jsfiddle" },
      { class: "fa fa-lastfm", name: "lastfm" },
      { class: "fa fa-lastfm-square", name: "lastfm-square" },
      { class: "fa fa-leanpub", name: "leanpub" },
      { class: "fa fa-linkedin", name: "linkedin" },
      { class: "fa fa-linkedin-square", name: "linkedin-square" },
      { class: "fa fa-linode", name: "linode" },
      { class: "fa fa-linux", name: "linux" },
      { class: "fa fa-maxcdn", name: "maxcdn" },
      { class: "fa fa-meanpath", name: "meanpath" },
      { class: "fa fa-medium", name: "medium" },
      { class: "fa fa-meetup", name: "meetup" },
      { class: "fa fa-mixcloud", name: "mixcloud" },
      { class: "fa fa-modx", name: "modx" },
      { class: "fa fa-odnoklassniki", name: "odnoklassniki" },
      { class: "fa fa-odnoklassniki-square", name: "odnoklassniki-square" },
      { class: "fa fa-opencart", name: "opencart" },
      { class: "fa fa-openid", name: "openid" },
      { class: "fa fa-opera", name: "opera" },
      { class: "fa fa-optin-monster", name: "optin-monster" },
      { class: "fa fa-pagelines", name: "pagelines" },
      { class: "fa fa-paypal", name: "paypal" },
      { class: "fa fa-pied-piper", name: "pied-piper" },
      { class: "fa fa-pied-piper-alt", name: "pied-piper-alt" },
      { class: "fa fa-pied-piper-pp", name: "pied-piper-pp" },
      { class: "fa fa-pinterest", name: "pinterest" },
      { class: "fa fa-pinterest-p", name: "pinterest-p" },
      { class: "fa fa-pinterest-square", name: "pinterest-square" },
      { class: "fa fa-product-hunt", name: "product-hunt" },
      { class: "fa fa-qq", name: "qq" },
      { class: "fa fa-quora", name: "quora" },
      { class: "fa fa-ra", name: "ra" },
      { class: "fa fa-ravelry", name: "ravelry" },
      { class: "fa fa-rebel", name: "rebel" },
      { class: "fa fa-reddit", name: "reddit" },
      { class: "fa fa-reddit-alien", name: "reddit-alien" },
      { class: "fa fa-reddit-square", name: "reddit-square" },
      { class: "fa fa-renren", name: "renren" },
      { class: "fa fa-resistance", name: "resistance" },
      { class: "fa fa-safari", name: "safari" },
      { class: "fa fa-scribd", name: "scribd" },
      { class: "fa fa-sellsy", name: "sellsy" },
      { class: "fa fa-share-alt", name: "share-alt" },
      { class: "fa fa-share-alt-square", name: "share-alt-square" },
      { class: "fa fa-shirtsinbulk", name: "shirtsinbulk" },
      { class: "fa fa-simplybuilt", name: "simplybuilt" },
      { class: "fa fa-skyatlas", name: "skyatlas" },
      { class: "fa fa-skype", name: "skype" },
      { class: "fa fa-slack", name: "slack" },
      { class: "fa fa-slideshare", name: "slideshare" },
      { class: "fa fa-snapchat", name: "snapchat" },
      { class: "fa fa-snapchat-ghost", name: "snapchat-ghost" },
      { class: "fa fa-snapchat-square", name: "snapchat-square" },
      { class: "fa fa-soundcloud", name: "soundcloud" },
      { class: "fa fa-spotify", name: "spotify" },
      { class: "fa fa-stack-exchange", name: "stack-exchange" },
      { class: "fa fa-stack-overflow", name: "stack-overflow" },
      { class: "fa fa-steam", name: "steam" },
      { class: "fa fa-steam-square", name: "steam-square" },
      { class: "fa fa-stumbleupon", name: "stumbleupon" },
      { class: "fa fa-stumbleupon-circle", name: "stumbleupon-circle" },
      { class: "fa fa-superpowers", name: "superpowers" },
      { class: "fa fa-telegram", name: "telegram" },
      { class: "fa fa-tencent-weibo", name: "tencent-weibo" },
      { class: "fa fa-themeisle", name: "themeisle" },
      { class: "fa fa-trello", name: "trello" },
      { class: "fa fa-tripadvisor", name: "tripadvisor" },
      { class: "fa fa-tumblr", name: "tumblr" },
      { class: "fa fa-tumblr-square", name: "tumblr-square" },
      { class: "fa fa-twitch", name: "twitch" },
      { class: "fa fa-twitter", name: "twitter" },
      { class: "fa fa-twitter-square", name: "twitter-square" },
      { class: "fa fa-usb", name: "usb" },
      { class: "fa fa-viacoin", name: "viacoin" },
      { class: "fa fa-viadeo", name: "viadeo" },
      { class: "fa fa-viadeo-square", name: "viadeo-square" },
      { class: "fa fa-vimeo", name: "vimeo" },
      { class: "fa fa-vimeo-square", name: "vimeo-square" },
      { class: "fa fa-vine", name: "vine" },
      { class: "fa fa-vk", name: "vk" },
      { class: "fa fa-wechat", name: "wechat" },
      { class: "fa fa-weibo", name: "weibo" },
      { class: "fa fa-weixin", name: "weixin" },
      { class: "fa fa-whatsapp", name: "whatsapp" },
      { class: "fa fa-wikipedia-w", name: "wikipedia-w" },
      { class: "fa fa-windows", name: "windows" },
      { class: "fa fa-wordpress", name: "wordpress" },
      { class: "fa fa-wpbeginner", name: "wpbeginner" },
      { class: "fa fa-wpexplorer", name: "wpexplorer" },
      { class: "fa fa-wpforms", name: "wpforms" },
      { class: "fa fa-xing", name: "xing" },
      { class: "fa fa-xing-square", name: "xing-square" },
      { class: "fa fa-y-combinator", name: "y-combinator" },
      { class: "fa fa-y-combinator-square", name: "y-combinator-square" },
      { class: "fa fa-yahoo", name: "yahoo" },
      { class: "fa fa-yc", name: "yc" },
      { class: "fa fa-yc-square", name: "yc-square" },
      { class: "fa fa-yelp", name: "yelp" },
      { class: "fa fa-yoast", name: "yoast" },
      { class: "fa fa-youtube", name: "youtube" },
      { class: "fa fa-youtube-play", name: "youtube-play" },
      { class: "fa fa-youtube-square", name: "youtube-square" },
      { class: "fa fa-warning", name: "warning" }
    ]
  },
  {
    id: "medical",
    name: "Medical Icons",
    icons: [
      { class: "fa fa-ambulance", name: "ambulance" },
      { class: "fa fa-h-square", name: "h-square" },
      { class: "fa fa-heart", name: "heart" },
      { class: "fa fa-heart-o", name: "heart-o" },
      { class: "fa fa-heartbeat", name: "heartbeat" },
      { class: "fa fa-hospital-o", name: "hospital-o" },
      { class: "fa fa-medkit", name: "medkit" },
      { class: "fa fa-plus-square", name: "plus-square" },
      { class: "fa fa-stethoscope", name: "stethoscope" },
      { class: "fa fa-user-md", name: "user-md" },
      { class: "fa fa-wheelchair", name: "wheelchair" },
      { class: "fa fa-wheelchair-alt", name: "wheelchair-alt" }
    ]
  }
];
