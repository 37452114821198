var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"page-home"},[_c('div',{staticClass:"inner"},[_c('ul',{staticClass:"action-list row"},[(1 === 2)?_c('li',{staticClass:"action-item col-4",on:{"click":function($event){return _vm.handleJump('/')}}},[_c('h1',{staticClass:"title"},[_vm._v("Home")]),_vm._m(0)]):_vm._e(),_c('li',{staticClass:"action-item col-4",on:{"click":function($event){return _vm.handleJump('/ui')}}},[_c('h1',{staticClass:"title"},[_vm._v("UI")]),_vm._m(1)]),_c('li',{staticClass:"action-item col-4",on:{"click":function($event){return _vm.handleJump('/admin/welcome')}}},[_c('h1',{staticClass:"title"},[_vm._v("Admin UI")]),_vm._m(2)]),_c('li',{staticClass:"action-item col-4",on:{"click":function($event){return _vm.handleJump('/icon')}}},[_c('h1',{staticClass:"title"},[_vm._v("Icon")]),_vm._m(3)])]),_c('ul',{staticClass:"action-list row"},[_c('li',{staticClass:"action-item col-4",on:{"click":function($event){return _vm.handleJump('/code')}}},[_c('h1',{staticClass:"title"},[_vm._v("Code")]),_vm._m(4)]),_c('li',{staticClass:"action-item col-4",on:{"click":function($event){return _vm.handleJump('/note')}}},[_c('h1',{staticClass:"title"},[_vm._v("Note")]),_vm._m(5)]),_c('li',{staticClass:"action-item col-4",on:{"click":function($event){return _vm.handleJump('/share')}}},[_c('h1',{staticClass:"title"},[_vm._v("Share")]),_vm._m(6)])]),_c('ul',{staticClass:"action-list row"},[_c('li',{staticClass:"action-item col-4",on:{"click":function($event){return _vm.handleJump('/color')}}},[_c('h1',{staticClass:"title"},[_vm._v("Color")]),_vm._m(7)]),_c('li',{staticClass:"action-item col-4",on:{"click":function($event){return _vm.handleJump('/pages')}}},[_c('h1',{staticClass:"title"},[_vm._v("Pages")]),_vm._m(8)]),_c('li',{staticClass:"action-item col-4",on:{"click":function($event){return _vm.handleJump('/imgs')}}},[_c('h1',{staticClass:"title"},[_vm._v("Imgs")]),_vm._m(9)])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content"},[_c('i',{staticClass:"fa fa-home fa-4x"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content"},[_c('i',{staticClass:"fa fa-paper-plane fa-3x"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content"},[_c('i',{staticClass:"fa fa-bars fa-3x"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content"},[_c('i',{staticClass:"fa fa-sun-o fa-3x"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content"},[_c('i',{staticClass:"fa fa-code fa-3x"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content"},[_c('i',{staticClass:"fa fa-edit fa-3x"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content"},[_c('i',{staticClass:"fa fa-share-alt-square fa-3x"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content"},[_c('i',{staticClass:"fa fa-th fa-3x"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content"},[_c('i',{staticClass:"fa fa-th fa-3x"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content"},[_c('i',{staticClass:"fa fa-th fa-3x"})])
}]

export { render, staticRenderFns }