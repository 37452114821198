var render = function render(){var _vm=this,_c=_vm._self._c;return _c('button',{class:[
    'hi-button',
    'hi-button-' + _vm.type,
    'hi-button-size-' + _vm.size,
    {
      'is-plain': _vm.plain,
      'is-block': _vm.block
    }
  ],style:({ borderRadius: _vm.radius + 'px', width: _vm.width, height: _vm.height }),attrs:{"disabled":_vm.disabled},on:{"click":_vm.handleClick}},[(_vm.icon)?_c('i',{class:['fa', _vm.icon]}):_vm._e(),_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }