export const articles = [
  {
    id: 1,
    title: "javascript简介",
    desc: "",
    content: "",
    author: "Mr.L",
    tags: "js",
    category: "前端技术",
    status: "0",
    create_time: "2019.10.11",
    publish_time: "2019.10.12",
    last_update_time: "2020.01.10"
  },
  {
    id: 2,
    title: "javascript简介",
    desc: "",
    content: "",
    author: "Mr.L",
    tags: "js",
    category: "前端技术",
    status: "1",
    create_time: "2019.10.11",
    publish_time: "2019.10.12",
    last_update_time: "2020.01.10"
  },
  {
    id: 3,
    title: "javascript简介",
    desc: "",
    content: "",
    author: "Mr.L",
    tags: "js",
    category: "前端技术",
    status: "0",
    create_time: "2019.10.11",
    publish_time: "2019.10.12",
    last_update_time: "2020.01.10"
  },
  {
    id: 4,
    title: "javascript简介",
    desc: "",
    content: "",
    author: "Mr.L",
    tags: "js",
    category: "前端技术",
    status: "0",
    create_time: "2019.10.11",
    publish_time: "2019.10.12",
    last_update_time: "2020.01.10"
  },
  {
    id: 5,
    title: "javascript简介",
    desc: "",
    content: "",
    author: "Mr.L",
    tags: "js",
    category: "前端技术",
    status: "1",
    create_time: "2019.10.11",
    publish_time: "2019.10.12",
    last_update_time: "2020.01.10"
  },
  {
    id: 6,
    title: "javascript简介",
    desc: "",
    content: "",
    author: "Mr.L",
    tags: "js",
    category: "前端技术",
    status: "0",
    create_time: "2019.10.11",
    publish_time: "2019.10.12",
    last_update_time: "2020.01.10"
  },
  {
    id: 7,
    title: "javascript简介",
    desc: "",
    content: "",
    author: "Mr.L",
    tags: "js",
    category: "前端技术",
    status: "0",
    create_time: "2019.10.11",
    publish_time: "2019.10.12",
    last_update_time: "2020.01.10"
  },
  {
    id: 8,
    title: "javascript简介",
    desc: "",
    content: "",
    author: "Mr.L",
    tags: "js",
    category: "前端技术",
    status: "1",
    create_time: "2019.10.11",
    publish_time: "2019.10.12",
    last_update_time: "2020.01.10"
  },
  {
    id: 9,
    title: "javascript简介",
    desc: "",
    content: "",
    author: "Mr.L",
    tags: "js",
    category: "前端技术",
    status: "0",
    create_time: "2019.10.11",
    publish_time: "2019.10.12",
    last_update_time: "2020.01.10"
  }
];
