export const shares = [
  {
    id: 1,
    title: "技术书籍",
    url: "https://pan.baidu.com/s/1GfHHmi8Yof3OAZJKDb0kaA",
    content:
      "链接: https://pan.baidu.com/s/1GfHHmi8Yof3OAZJKDb0kaA 提取码: gn1c"
  },
  {
    id: 2,
    title: "周董的歌",
    url: "https://pan.baidu.com/s/1V8MaRYLxRubB1HvO-h9mcA",
    content:
      "链接: https://pan.baidu.com/s/1V8MaRYLxRubB1HvO-h9mcA 提取码: qada"
  },
  {
    id: 3,
    title: "逼哥的歌",
    url: "https://pan.baidu.com/s/1pobw2o4dWAk0kUESRC_3dQ",
    content:
      "链接: https://pan.baidu.com/s/1pobw2o4dWAk0kUESRC_3dQ 提取码: 7tgi"
  }
];
